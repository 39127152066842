@mixin accelerate($transform) {
  transform: translateZ(0) $transform;
  backface-visibility: hidden;
  perspective: 1000px;
}
@mixin for-phone-only {
  @media (max-width: 599px){
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media screen and (min-width: 600px){
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 900px){
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px){
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px){
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px){
    @content;
  }
}
@mixin for-desktop-middle-up {
  @media (min-width: 1500px){
    @content;
  }
}
@mixin landscape-on-mobile {
  @media screen and (orientation:landscape) and (min-width: 600px){
    @content;
  }
}
@mixin portrait-on-mobile {
  @media screen and (orientation : portrait) and (min-width: 600px){
    @content;
  }
}
.b-test {
  width: 100%;
  @include for-tablet-portrait-up {
    width: 50%;
  }
}
