.b-text-list {

}
.text-list--title {
  text-transform: uppercase;
}
.text-list--item {
  width:100%;
  flex: 1 0 auto;
  @include for-tablet-portrait-up {

  }
  @include landscape-on-mobile {
    width:50%;
  }
  &:first-child {
    @include for-tablet-portrait-up {
      padding-right: 1%;
    }
  }
  &:last-child {
    @include for-tablet-portrait-up {
      padding-left: 1%;
    }
  }
}
.text-list--holder {
  width:100%;
  @include landscape-on-mobile {
    flex-direction: row;
  }
  @include for-tablet-portrait-up {

  }
}