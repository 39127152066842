.b-form
{
  width: 320px;
  height: 420px;
  background-color: #ffffff;
  padding: 39px 60px 50px 60px;
  position: relative;
  margin:0 auto;
  @include for-tablet-portrait-up {
    width: 520px;
    height: 520px;
    //margin-left: 5%;
  }

  &:before {
    content:'';
    display:block;
    width:100%;
    height:20px;
    position: absolute;
    top:-15px;
    left:0;
    background: url('/assets/img/back/form-top.png') no-repeat;
    background-size:contain;
  }
  &:after {
    content:'';
    display:block;
    width:100%;
    height:20px;
    position: absolute;
    bottom:-15px;
    left:0;
    background: url('/assets/img/back/form-bot.png') no-repeat;
    background-size:contain;
  }
}
.form--title {
  color: $accent-color;
  font-family: "Gothic";
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  padding: 0.7em 0;
  &:before {
    content:'';
    display:block;
    width: 201px;
    height:9px;
    background: url('/assets/img/sprite.svg') no-repeat;
    background-position: 0 0;
    background-size:cover;
    left:50%;
    transform:translateX(-50%);
    position: absolute;
    top:0;
  }
  &:after {
    content:'';
    display:block;
    width: 201px;
    height:9px;
    background: url('/assets/img/sprite.svg') no-repeat;
    background-position: 0 0;
    background-size:cover;
    left:50%;
    transform:translateX(-50%);
    position: absolute;
    bottom:0;
  }
}
.b-btn {
  @include for-phone-only {
    margin-top: 1.5em;
  }
}
.form--btn {
  display: flex;
  justify-content: flex-end;

}
