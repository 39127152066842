.b-deco-view {
  padding: 22px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: url(/assets/img/back/bg-elem-right.png)no-repeat;
    background-size:100%;
    width:13%;
    top:3%;
    height:100%;
    left:-8.9%;
  }
  &:after {
    content: '';
    position: absolute;
    width: 70%;
    top: 0;
    bottom:0;
    left:0;
    border: 1px solid #117bc7;
    z-index: -1;
  }
}
.deco-view--video {
  width:95%;
  min-height: 335px;
  height:100%;
  iframe {
    width:100%;
    min-height: 335px;
  }
}
.deco-view--slider {
  width:95%;
  min-height: 335px;
  height:100%;
  .c-slider{
    width:100%;
    min-height: 335px;

  }
  .slider--item_img {
    width:auto;
    height: 100%;
  }
}
.b-deco-view + * {
  padding-top:22px;
}
