h1, .t-h1 {
  font-family: "Gothic", sans-serif;
  font-size: 2.25rem;
  line-height: 1;
  margin-bottom: 1em;
  text-align: center;
  margin-top: 0;
  font-weight: 400;
}
h2, .t-h2 {
  font-family: "Gothic",sans-serif;
  font-size: 1.87rem;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 1.3em;
  font-weight: 400;
  position: relative;
}
h3, .t-h3 {
  font-family: "Gothic", sans-serif;
  font-size: 1.5rem;
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0;
  font-weight: 400;
}

h4, .t-h4 {
   font-family: "Gothic", sans-serif;
   font-size: 1.25rem;
   line-height: 1;
   margin-bottom: 1.2em;
   font-weight: 400;
 }
h5, .t-h5 {
  font-family: "Gothic", sans-serif;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 2em;
  font-weight: 400;
}
h3 + *{
  margin-top: 0;
}
h2 + *{
  margin-top: 0;
}
p {
  font-size: 1.2rem;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: 1.1em;
  a {
    color:$accent-color;
    border-bottom: 1px solid currentColor;
    transition:all 0.3s;
    &:hover {
      color:$red;
    }
  }
}
p + h3 {
  margin-top: 1em;
}
p + h2 {
  margin-top: 1em;
}
p:first-child {
  margin-top: 0;
}
.u-user-text {
  ul {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-top: 1em;
    li {
      margin-top: 0.7em;
      position: relative;
      padding-left: 1em;
      &:before {
        content:'';
        position: absolute;
        left:0;
        top:0.2em;
        width:0.3em;
        height:0.3em;
        background: $accent-color;
      }
    }
  }
  strong {
    padding-left: 2.8em;
    position: relative;
    &:before {
      content:'';
      position: absolute;
      left:2.4em;
      width:0.5em;
      background: $accent-color;
      top:0;
      bottom:0.1em;
      font-style: italic;
    }
  }
  ol {
    li {

      &:before {
        counter-increment: item;
        content: counter(item) ".";
        color:$accent-color;
        margin-right: 0.5rem;
      }
    }
  }
}

.t-user-text {
  img {
    max-width: 100%;
    margin-top: 1em;
    margin-bottom: 2em;
  }
  h2 {
    color:$accent-color;
  }

}
