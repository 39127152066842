.b-styled-list {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 58px 0;
  @include for-desktop-up {
    justify-content: space-between;
  }

}
.styled-list--item {
  display:flex;
  flex-wrap: wrap;
  min-width: 350px;
  align-content: center;
  align-items: center;
  @include for-tablet-portrait-up {
    min-width: 500px;
  }
}
.styled-list--text-title {
  font-family: "Gothic", sans-serif;
  font-size: 4.5rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  color:$accent-color;
  margin-bottom: 0.1em;
  span {
    font-size: 30%;
    display: inline-block;
    padding-left: 0.3em;
  }
}
.styled-list--text {
  width:300px;
  padding-left:0.5em;
  @include for-phone-only {
    width:250px;
  }
}
.styled-list--text-text {
  font-size: 1.4rem;

}
.styled-list--img {
  width:100px;
  height:100px;
  &:before {
    content: '';
    width: 142%;
    height: 129%;
    background-size: 50% 90%;
    left:-20%;
  }
  @include for-tablet-portrait-up {
    width:200px;
    height:200px;
  }

}