.b-social-ico
{
  border-radius: 10px;
  padding: 10px;
  background-color: white;

}
.b-social-ico_list
{
  .social-ico_item
  {
    display: inline-flex;
    width: 80px;
    height: 36px;
    border: 1px solid #999999;;
    border-radius: 6px;
    .social-ico_item-logo
    {
      position: relative;
      width: 35px;
      height: 35px;
      top:50%;
      transform: translateY(-50%);
      svg {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
    .social-ico_item-like
    {
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      p
      {
        margin: 0;
        color: #999999;
      }
    }
  }
}
