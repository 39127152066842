.b-article-prev {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 52px 0 100px 0;
  &.no-img {
    .article-prev--img {
      display: none;
    }
    .article-prev--text {
      max-width: 100%;
      min-height: 100%;
      &:after {
        width:100%;
      }
    }
  }
}
.article-prev--img {
  flex:2;
  max-width: 660px;
  position: relative;
  img {
    width:100%;
    min-width: 300px;
  }


}
.article-prev--text {
  flex:2;
  position: relative;
  padding: 20px 48px 20px 61px;
  max-width: 459px;
  min-height: 380px;
  min-width: 300px;
  &:after {
    content: '';
    position:absolute;
    width:170%;
    height: calc(100% + 44px);
    top: -25px;
    bottom: -22px;
    right: 0;
    border: 1px solid $accent-color;
    z-index: -1;
  }

}
.article-prev--deco {
  position: relative;

  &:before{
    content: '';
    position: absolute;
    top:1%;
    left:-1px;

    background-image:linear-gradient(to bottom,  rgba(0,0,0,0), rgba(0,0,0,0.5));
    width:10%;
    bottom: 2%;
    transform: rotate(-4deg);
  }
  &:after{
    content: '';
    position: absolute;
    top:2%;
    left:-1px;
    background-image:linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.5));
    width:10%;
    bottom: 1%;
    transform: rotate(4deg);
    z-index: 1;
  }
  .b-btn {
    display: none;

  }
  .article-prev--img {
    position:relative;
    z-index: 10;
  }
  .article-prev--text {
    p{
      font-size: 1.7rem;
      font-style: italic;
    }
  }
}
.article-prev--title {
  color: $accent-color;
  margin-top: 0;
}

