.b-deco-list {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 38px;
  list-style: none;
  padding: 0;
  margin-bottom: 85px;
}
.deco-list--item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list--item_main {
  color: #ffffff;
  font-size: 2.5rem;
  padding:0.1em 0.7em;
  background: url("/assets/img/back/factsbg.png")no-repeat;
  background-size:100% 100%;
  width: 185px;
  margin-bottom: 0.4em;
  margin-top: 0.2em;
}
.list--item_desc {
  font-family: "Gothic";
  font-size: 1.5rem;
  margin:0;
  max-width: 300px;
}
