.b-form-modal
{
    position: fixed;
    z-index: 1000;
    z-index: -1;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    transform-origin: (0 0);

    border: 1px solid grey;
    background: #fff;
}
.form-modal-inner
{
    opacity: 0;
}
.modal-block-close
{
    position: absolute;
    top: .3rem;
    right: .5rem;
}
