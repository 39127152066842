.b-deco-head {
  width:100%;
  border-top: 3px solid #087cc8;
  border-bottom: 3px solid #087cc8;
  height: 70px;
  font-size: 36px;
  font-weight: 600;
  position:relative;

}
.b-deco-head_text  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  span {
    padding: 0;
  }
}
.el-deco-head-arrow-right {
  width: 55px;
  height: 64px;
  background: url(/assets/img/arrow.png) no-repeat;
  background-size: 54%;
  background-position: right;
  position: absolute;
  right: 5%;
  top: 0;
}
.deco-head-input-field {
  width: 45px;

  input {
      font-size: 36px;
      padding-right: 4px;
      border: none;
  }
}
