.b-phone-header
{
    display: none;

    text-align: right;

    @media (min-width: 890px)
    {
        display: inherit;
    }
}
.phone-header_num
{
    margin-right: 18px;

    color: currentColor;
}
.phone-header_text
{
    margin-top: 5px;

    cursor: pointer;

    color: currentColor;

    &:before
    {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        content: '';

        background: url('/assets/img/svg/phone.svg');
        background-size: contain;
    }
}
