.b-lang {
  display:inline-flex;
  font-size: 0.8rem;
  color:$accent-color;
}
.lang-item {
  display:flex;
  align-items: center;
  align-content: center;
  margin-right: 10px;
  transition: all 0.5s ease;
  cursor:pointer;
  img {
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 50%;
    border:1px solid transparent;
    margin-right: 5px;
    opacity: 0.7;
  }
  &.is-active {
    color:$base-color;
    img {
      border:1px solid $accent-color;
      opacity: 1;
    }
  }
  &:hover {
    img {

      opacity: 1;
    }

  }
}
