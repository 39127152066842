.b-plan {
  width:70%;
  max-height: 500px;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  &.__flat {
    width:45%;
  }
  @include for-desktop-up {
    transform: translateY(-50%);
    left:auto;
    width:70%;
  }
  .el-plan-img {
    width:100%;

  }
  .el-plan-svg {
    position:absolute;
    width:100%;
    top:0;
    left:0;
    svg {
      width:100%;
      position: absolute;
      top:0;
      left:0;
    }
  }
}
.el-plane-svg_path{
  opacity: 0.8;
  cursor: pointer;
  &._sale {
    fill:$legend-sale-color;
    .car{
      opacity: 0;
    }
  }
  &._sold {
    fill:$grey-light-color;
  }
  &._reserved {
    fill:#c6e7ff;
  }

  &:hover {
    opacity: 0.8;
  }
  &.active {
      animation: blinkOpacity  1s 3;
  }
  &.j-park {
      &._reserved {
        text {
          opacity:0;
        }
    }
  }
}
.el-plane-svg_path._reserved {
  fill:#c6e7ff;
}
.el-plane-svg_path._reserved path {
    fill:#fff;
}
