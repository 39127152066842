.b-fact-item {
  display: flex;
  align-items:center;
}
.fact-item--img {
  height: 65px;
  width: 68px;
  color:$accent-color;
  position: relative;
  margin-right: 13px;
  svg {
    width:100%;
    height:100%;
  }
  &:before {
    content:"";
    position: absolute;

    background: url(/assets/img/mini.png) no-repeat;
    background-size: 37px 65px;
    top:0;
    left:0;
    height:65px;
    width:37px;
  }
}
.fact-item--text {
  p {
    margin: 0;
  }
}
.fact-item--text_title {
  font-family: "PT Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}
