.b-video-all {
  display: flex;
  flex-wrap: wrap;
  width:100%;
  @include for-phone-only {
    flex-direction: column;
  }
  @include for-tablet-portrait-up {
    flex-direction: column;
  }
  @include for-tablet-landscape-up {
    flex-direction: row;
  }
  .b-video-holder {
    width: 100%;
    order:-1;
    flex: 1 0 auto;
    @include for-desktop-up {
      flex:3;
      min-width:0;
      order:2;
    }
    @include for-desktop-middle-up {
      flex:4;
    }
  }
  .b-video-desc-holder{
    padding:0 2%;
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    @include for-desktop-up {
      flex: 3 0 auto;
      min-width:0;
      max-width: 500px;
    }
    @include for-desktop-middle-up {
      flex: 2 0 auto;
    }
  }
  .b-video-desc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include for-desktop-up {
      justify-content: flex-start;
    }
  }
  .video-desc--item {
    width: 100%;
    padding-right: 3%;
    flex: 1 0 auto;
    @include for-desktop-up {
      width:100%;
      padding-right: 0;
      margin-bottom: 1em;
    }
    @include landscape-on-mobile {
      width:50%;
    }
    @include for-tablet-landscape-up {
      width: 50%;
    }
    @include for-desktop-up {
      width: 100%;
    }
    h4 {
      @include for-desktop-up {
        margin-top: 0;
      }

    }
  }
}