.b-monohrom-link {
  color:currentColor;
  border-bottom: 1px solid currentColor;
  margin-right: .8em;
  &:hover {
    border-bottom: 1px solid transparent;
  }

}

