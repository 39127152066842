.row-half-spa {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
}
.row-half-spa > * {
        width:47%;
}
.row-half-spb {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
}
.row-half-spb > * {
        min-width: 48%;
        width: calc((900px - 100%) * 1000);
        max-width: 100%;
        //flex:1 0 300px;
}
.row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
}
.ai--end {
        align-items: flex-end;
}
.row-jc-center {
        justify-content: center;
}
.row__no-wrap {
      flex-wrap: nowrap;
}
$gutter:30px;
@for $i from 1 through 12 {
        $width: percentage(1 / $i);
        .col-#{$i} {
                width:calc(#{$width} - #{$gutter});
                flex: #{$i} 0 auto;
        }
        .col-#{$i}:first-child {
                margin-left: 0;
        }
}