.b-btn-left
{
  display: inline-flex;
  align-items: center;
  color: black;
  .b-btn-left-image
  {
    position: relative;
    width: 24px;
    height: 24px;
    color:#15BBDD;
    svg
    {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  .b-btn-left-text
  {
    font-size: .9rem;
    margin-left: 1rem;
  }
}
