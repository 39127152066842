.b-deco-img {
  width: 200px;
  height: 200px;
  border: 1px solid $accent-color;
  border-radius: 50%;
  position: relative;
  padding: 5px;
  margin-bottom: 20px;
  &:before {
    content: '';
    width: 105px;
    height: 182px;
    background: url("/assets/img/back/circle-back.png")no-repeat;
    background-size:105px 182px;
    display: block;
    top:50%;
    position: absolute;
    transform: translateY(-50%);
    left:-60px;
    opacity:0.8;
    z-index: 1;
  }
  img {
    width:100%;
    border-radius: 50%;
    position: relative;
    z-index: 10;
  }
}
