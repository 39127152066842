
.b-desc-list {


}
.el-desc-list_item{
  line-height: 1.7;
  &._title {
    color: #087cc8;
    line-height: 1.5;
    border-bottom: 1px solid #087cc8;
    margin-bottom: 10px;
  }
}
.el-desc-list_btn {
  margin-left: 8%;
}
