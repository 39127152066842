.c-two-columns
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  //****CONTENT*****
  .column-content
  {
    width: 65%;
    max-width: 800px;
    overflow: hidden;
  }
  //****ASIDE*****
  .column-aside
  {
    width: 35%;
    max-width: 300px;
    .b-social-ico
    {
       position: fixed;

      bottom: 70px;

    }
  }
}
