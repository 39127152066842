.c-articles-list {
  .b-article-prev {
    &:nth-child(odd){
      flex-direction: row-reverse;
      .article-prev--text {
        &:after {
          right: auto;
          left:0;
        }
      }
    }
  }

}

