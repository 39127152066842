.b-filter-form {
  display:flex;
  align-items: center;
  padding: 2% 0;
  span {
    padding: 0;
  }
  margin-bottom: auto;
}
.filter-form {

}
.filter-form-title {

}
.form-ches-link {
  text-align: center;
}

