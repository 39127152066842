.b-step {
   align-items: center;
  margin-bottom: 76px;
}
.step--title {
  display:flex;
  align-items: flex-end;
  font-size: 1.5rem;
  margin-bottom: 1.1em;
}
.step--text {
  max-width: 460px;
}
.step--img {
  max-width: 460px;
  img {
    width:100%;
  }
}
.step--title_num {
  font-size: 5em;
  color:$accent-color;
  line-height: 0.83;
  font-family:'PT Sans', sans-serif;
  font-weight: 400;
}
.step--title_text {
  margin-left: 1em;
  padding-right: 2em;
  line-height: 1.3;
}
