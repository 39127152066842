.c-main-img {
  display: flex;
  height:calc(100vh - 154px);
  overflow: hidden;
  justify-content: space-between;
  position:relative;
  z-index: 1;

}
.main-img--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position:relative;
  flex-basis: 34%;
  padding: 0 10% 33px 10%;
  overflow: hidden;
  .b-btn {
    position: relative;
    z-index: 10;
  }
  &:before {
    content:'';
    position: absolute;
    bottom: 0;
    width:100%;
    height:80%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }

  img {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition:all  1s ease;
  }
  &:hover {
    img:last-child {
      opacity: 0;
    }
    @supports(-webkit-clip-path: polygon(100% -100%, -100% 100%, 100% 100%)) or (clip-path: polygon(100% -100%, -100% 100%, 100% 100%))
    {
      img:last-child {
        opacity: 1;
      }
      .img-holder {
        clip-path: polygon(100% -100%, -100% 100%, 100% 100%);
      }
    }

  }
  [data-show="1"] {
    opacity: 1;
  }
}

.main-img--title {
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.97);
  color: #ffffff;
  font-family: "Gothic";
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 23px;
  text-align: center;
  position: relative;
  z-index: 10;
}
.img-holder {
  position: absolute;
  top:0;
  left:0;
  bottom: 0;
  right:0;
  overflow: hidden;
  border-right: 4px solid #fff;
  @supports(-webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%)) or (clip-path: polygon(100% 0, 0% 100%, 100% 100%)){
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }

  transition: all 1s ease;


  img {
    position: relative;
  }
}
