.b-breadcrumbs {
  margin: 26px 0 0 0;
  color: $light-grey;
  font-size: 14px;
  margin-bottom: 36px;
  flex: 1;
  text-align: right;
  a {
    position: relative;
    transition: color 0.5s ease;
    &:hover {
      color: #eb2f2f;
    }
  }
  a:after {
    content: '/';
    font-size: 117%;
    padding: 0 3px 0 2px;
    display: inline-block;
    position: absolute;
    right: -11px;
    bottom: -1px;
  }

}
.breadcrumbs--divider  {
  font-size: 120%;
  padding: 0 3px 0 2px;
  display: inline-block;
  margin-bottom: -0.5px;
}
