.b-input-text {
  position: relative;
  margin-bottom: 1em;
}
.input-text--inp {
  width:100%;
  border:none;
  height:35px;
  border-bottom: 1px solid $light-grey;
  outline: transparent;
  padding-left: 0.5em;
}
.input-text--label {
  position: absolute;
  color:$light-grey;
  left:50%;
  bottom:5px;
  transition:all 0.5s ease;
  transform: translateX(-50%);
  width:100%;
  text-align: center;
}
.input-text--inp:focus + label {
  bottom:-50%;
  color:currentColor;
  font-size: 80%;
}
.input-text--inp:valid + label {
  bottom:-50%;
  color:currentColor;
  font-size: 80%;
}
.input-text--textarea {
  border: 1px solid $light-grey;
  width:100%;
  height:10em;
  margin-top: 2em;
  padding: 0.5em;
}

