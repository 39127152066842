.b-studio-info {
  width: 168px;
  height: 50px;
  background-image: linear-gradient(-80.7deg, #dbdbdb, #fcf9f6);
  border:1px solid #bfbfbf;
  position: relative;
  padding: 3px;
  display: block;

}
.studio-info_text {
  background-image: linear-gradient(80.7deg,#fcf9f6, #dbdbdb);
  width:100%;
  height:100%;
  color: #a62602;
  font-size: 12px;
  line-height: 1.2;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding-left: 10px;
  .studio-info_text-phone
  {
    font-size: 1.3rem;
    font-weight: 600;
  }
  p {
    margin: 0;
  }
  a {
    color:currentColor;
  }
}
