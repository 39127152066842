.b-full-img {
   height: 70vh;
   width:100%;
   position: relative;
   overflow: hidden;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   img {
     position: absolute;
     min-width: 100%;
     min-height: 100%;
     bottom:0;
     left:50%;
     transform: translateX(-50%);
     z-index: 0;
   }
}
.full-img--desc {
  height:20%;
  min-height: 50px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 35%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
  position: relative;
  z-index: 10;
  display:flex;
  flex-direction: column;
  justify-content:  flex-end;
  padding-bottom: 36px;
  .u-main-wr {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  p {
    margin: 0;
    font-size: inherit;
  }
  color: #ffffff;
  font-family: 'Gothic';
  font-size: 1.5rem;
}
