.b-promo-staff {
   display: flex;
   flex-direction: column;
   align-items: center;
  text-align: center;
}
.promo-staff--img {
  width: 280px;
  height: 280px;
  border: 1px solid $accent-color;
  border-radius: 50%;
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
  &:before {
    content: '';
    width: 132px;
    height: 228px;
    background: url("/assets/img/back/circle-back.png")no-repeat;
    background-size:132px 228px;
    display: block;
    top:50%;
    position: absolute;
    transform: translateY(-50%);
    left:-65px;
    opacity:0.8;
    z-index: 1;
  }
  img {
    width:100%;
    border-radius: 50%;
    position: relative;
    z-index: 10;
  }
}
.promo-staff--btn {
  display: flex;
  justify-content: center;
}
.promo-staff--text {
  p{
    margin-top: 0.5em;
  }
}
.promo-staff--title {
  margin-bottom: 0;
}
