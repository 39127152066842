.b-check-btn {
  margin-right: 26px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top:-2px;
    left:-2px;
    width:calc(100% + 4px);
    bottom:-2px;
    background-image: linear-gradient(to right, #f8cd5f 0%, #daa944 29%, #c08726 42%, #f6cb5f 55%, #bb8027 100%);
    z-index: -1;
  }
  &:hover {
    .check-btn--label {
      background-color:  #3a3a3a;
      color:#fff;
    }
  }
}
.check-btn--label {
  background-color: #ffffff;
  padding: 10px 14px;
  transition: all 1s ease;
  color: #8a2d20;
  display: block;
}
.check-btn--inp {
  visibility: collapse;
  width:0;
  position: absolute;
}
.check-btn--inp:checked + .check-btn--label {
  color:#fff;
  background-color: #3a3a3a;
}
