.b-project-aside
{
  display: flex;
  flex-direction: column;
  align-items: center;
  .project-header
  {
    margin-top: 0.5em;
    margin-bottom: 1.3em;
  }
  .b-half-img
  {
    margin-top: 0;
    display: block;
    width:100%;
  }
  .half-img--desc {
    display: block;
    width:100%;
    text-align: center;
  }
  .b-btn {
    margin: 0 auto;
  }

}
.b-project-aside + .b-social-ico {
  margin-top: 40px;
}
