
@font-face {
  font-family: "Gothic";
  src: url("/assets/fonts/gothic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&subset=cyrillic');
*
{
  box-sizing: border-box;
}
html
{
  font-size: 11px;
  min-height: 100vh;
  height: 100%;
  @include for-tablet-landscape-up {
    font-size: 13px;
  }

  @include for-desktop-up {
    font-size: 14px;
  }
  @include for-desktop-middle-up {
    font-size: 16px;
  }

}
body {
  font-family: 'PT Sans', sans-serif;
  color: $base-color;
  min-height: 100vh;

  font-weight: 300;
}
a {
  text-decoration: none;
}
.u-def-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height:100%;
  height: auto;

  width: 100%;
}
.u-main-wr {
  width:calc(100% - 15px);
  max-width: 1400px;
  margin: 0 auto;
  @include for-tablet-portrait-up {
    width:calc(100% - 40px);
  }
}
.u-content-holder {
  flex: 1 1 auto;
  background-image:url(/assets/img/back/bg-topleft.png),url(/assets/img//back/bg-centerleft.png), url(/assets/img//back/bg-topright.png), url(/assets/img//back/bg-botright.png);
  background-repeat: no-repeat;
  background-position: left top, left center, right top, right bottom;
  background-size:50%, 20%, 50%, 40%;
  position: relative;
  min-height:calc(100vh - 160px);
  height: auto;
  padding-top: calc(2% + 102px);

}
.u-centered {
  margin: 0 auto;
}
.overlay
{
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  background-color: rgba(186, 124, 112, 0.40);
  opacity: 0;
  z-index: -1;
  transition: opacity .8s;
  &.overlayShow
  {
    opacity: 1;
    z-index: 900;
  }
  &.is-active
  {
    opacity: 1;
    z-index: 900;
  }
}
.conteiner_click
{
  width: auto;
  height: auto;
  display: none;
  //border: 2px solid deeppink;
  position: absolute;
  z-index: 1000;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  //display: none;
  &.conteiner_click-show
  {
    display: block;
  }

  .close
  {

    right: -20px;


  }
}

.conteiner_main
{
  width: auto;
  height: auto;
  padding:3%;
  background: #fff;
  //border: 2px solid #4825ff;
}
//Button 'Close'
.close
{
  //display: none;
  position: absolute;
  top:10px;
  right: 10px;
  width: auto;
  height: auto;
  //z-index: 1100;

}
.u-accent-color {
  color:$accent-color;
}
.u-mg-bot {
  margin-bottom: 58px;
}
.u-mg-vert {
  margin:58px auto;
}
.u-accent-bg {
  background-image: linear-gradient(to right, $accent-color-dark 0%, $accent-color 100%);
  padding-top: 40px;
  color:#fff;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-top: 100px;
  margin-bottom: 58px;
  width:100%;
  overflow: hidden;
  p {
    font-size: inherit;
    line-height: inherit;
  }
}
.u-min-mg {
  margin-bottom: -7px;
  width:100%;
}
.u-text-centered {
  text-align: center;
}
.u-arr-bot {
  position: absolute;
  bottom: 0;
  left:50%;
  transform: translateX(-50%);
  width: 18px;
  height: 11px;
  svg {
    width:100%;
    height:100%;
  }
}
.u-bigger-text {
  font-size: 150%;
  font-style: italic;
}
.u-great {
  font-size: 180px;
  margin-bottom: 18px;
}
.u-right-bg {
  background: url("/assets/img/back/right.png")no-repeat;
  background-position: right 20%;
  background-size:10%;
}
.u-img-replace {
  background: $accent-color;
  width: 100%;
  height:100%;
  border-radius: inherit;
}
.u-abs {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  z-index: 110;
  color:#fff;
  .b-breadcrumbs {
    color:#fff;
  }
}
.u-full-img {
  height: 70vh;
  width:100%;
}
.u-fit {
  width:100%;
}
.u-slider-wr   {
    height:40vh;
    margin: 48px 0;
  @include for-tablet-landscape-up {
    height:70vh;
    margin: 48px 0;
  }
}
.u-pad-for-scroll {

}
#contacts {
  padding-top: 56px;
  padding-bottom: 200px;
}