.b-accent-row {
  padding-top:40px;
}
.accent-row--img {
  margin: 0;
  padding: 0;
  margin-top: -8%;
  overflow: hidden;
  max-height:215px;
  img {
    width:100%;
  }
}
