.b-nav
{
  position: relative;
  display: flex;
}
.nav_item {
  font-size: 11px;
  margin-right: 5px;
  text-transform: uppercase;
  color:$base-color;
  padding: 0.3em  0.6em;
  position:relative;

  @include for-tablet-portrait-up {
    font-size: 14px;
    margin-right: 5px;
  }
  @include for-tablet-landscape-up {
    font-size: 16px;
    margin-right: 20px;
  }
  &:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    bottom: 0;
    background: url('/assets/img/navbg.png')no-repeat;
    background-size:100% 100%;
    transition: all 1s ease;
    opacity:0;
    z-index: -1;
  }

  &:hover {
    &:before {
      opacity:1;
    }

    color:#fff;
  }

}
.nav-item__current {
  &:before {
    opacity:1;
  }
  color:#fff;
}
