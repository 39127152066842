.b-man-intro {
  display: flex;
  align-items: center;
}
.man-intro--img {
  width: 100px;
  height: 100px;
  border: 1px solid $accent-color;
  margin-right: 21px;
  border-radius: 50%;
  padding: 5px;
  img {
    width:100%;
    border-radius: 50%;
  }
}
.man-intro--text {
  p {
    margin: 0.3em 0;
  }
}
.man-intro--text_title {
  font-size: 1.5rem;

}
