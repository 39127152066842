.b-content-tabs-holder {
  position: relative;
  height:50vh;
  margin-bottom: 58px;
  border-bottom: 2px solid  #087cc8;
  @include landscape-on-mobile {
    height:100vh;
  }
  @include for-tablet-portrait-up {
    height:90vh;
  }
  @include for-desktop-up {

  }
}
.content-tabs--item {

  position: absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  overflow: hidden;
  justify-content: center;
  opacity: 0;
  z-index: -1;
  transition: opacity 1s ease;
  &.is-active {
    opacity: 1;
    z-index: 10;
  }
  .u-main-wr {
    height:100%;
    max-width: 1400px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.5);
  }
}