.c-main-footer {
  padding: 2% 0 ;
  background-color: #ecf9fd;
  border-top: 2px solid $accent-color;
  height:auto;
  @include for-tablet-landscape-up {
    height: 52px;
  }
  .u-main-wr {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height:auto;
    @include for-tablet-landscape-up {
      height:100%;
    }

  }
}
