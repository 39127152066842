.b-pdf-plan {
  height:80%;
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  @include portrait-on-mobile {
    height:50%;
  }
  @include for-desktop-up {
    height:80%;
    transform: translateX(-32%);
  }
  img {
    height: 100%;
  }
}