.b-range-item {
  margin-top: 26px;
  position: relative;
  .irs-slider {
    top: 20px;
    width: 23px;
    height: 12px;
    background: url('../images/ico/range.png')no-repeat;
    cursor: pointer;
    border-radius: 0;
    background-position: center;
    box-shadow: none;
    border: none;
  }
  .irs-from, .irs-to {
    display:none;
  }
  .irs-min, .irs-max {
    display:none;
  }
  .irs-bar {
    height: 7px;
    top: 11px;
    background: #ca9430;
    border-top: 1px solid #ca9430;
    border-bottom: 1px solid #ca9430;

  }
  .irs-line {
    height: 7px;
    top: 11px;
    background: transparent;
    border: none;
    border-radius: none;
  }
  .irs {
    height: 22px;
    margin-top: 5px;
  }
  .irs-line-mid {
    background:#444444;
    width:calc(100% - 20px);
    left:10px;
  }
  .irs-line-left, .irs-line-right{
    width: 10px;
    background: transparent;
  }
  p {
    padding: 0;
  }
}
.range-inpt {
  box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: 0;
  &[type="text"] {
    width: 72px;
    height: 31px;
    box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #cccccc;
    background-color: #ffffff;
    text-align: center;
    padding: 0;
  }

}
.range-item_title {
  position: absolute;
  bottom:13px;
  left:50%;
  transform:translateX(-50%);
  text-align: center;
}
