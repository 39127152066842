.b-ico-text {

  display: flex;
  align-items: center;
  .icon {
    color:$accent-color;
    margin-right: 10px;
    width: 41px;
    height: 41px;
  }
  p {
    margin: 0;
    color:$base-color;
  }
}
