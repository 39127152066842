.b-logo {
  display: block;
  height: 50px;
  margin-right:1%;
  margin-bottom: 10px;
  img{height: 100%;}
  @include for-tablet-landscape-up {
    height: 76px;
    margin-right:83px;
    margin-bottom: 0;
  }
}
