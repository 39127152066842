.b-video-holder {
 margin: 0 0 20px 0;
  position: relative;
 @include for-tablet-portrait-up {
   margin: 0 0 59px 0;
 }
   video {
     width:100%;
     box-shadow: 1px 2px 2px 3px rgba(0, 0,0, 0.3);
     margin-bottom: -3px;
   }

}
.video-holder--head {
  width:100%;
  img{
    width:100%;
    box-shadow: 1px 2px 2px 3px rgba(0, 0,0, 0.3);
  }
}
.video-start-play {
  position: absolute;
  top:0;
  left:0;
  bottom: 0;
  right:0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  opacity: 1;
  img {
    width:20%;
  }
  &.active {
    opacity: 0;
  }
  @include for-desktop-up {
    opacity: 0;
  }
}