.c-main-header {
  padding:16px 0 8px 0;
  border-bottom: 2px solid $accent-color;
  position: fixed;
  width:100%;
  background: #fff;
  z-index: 100;
  overflow: hidden;
  .u-main-wr {
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    @include for-phone-only {
      flex-direction: column;
      align-items: center;
    }
    @include for-tablet-portrait-up {
      justify-content: space-between;
      margin: 0 auto;
    }
  }
}
