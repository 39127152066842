.b-half-img {
   position: relative;
   overflow: hidden;
   max-width: 540px;
   display: flex;
   flex-direction: column;
   margin-top: 58px;
   img {
     width:100%;

     max-height: 297px;
     border-radius: 8px;
   }
}
.half-img--desc {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10%;
  font-size: 1.5rem;
  line-height: 1.4;

  p {
    margin: 0;
    margin-bottom: 12px;
    font-size: inherit;


  }

}
