.b-studio{
  font-size: 12px;
  color:inherit;
  img{height: 100%;}
  display: flex;
  align-items: center;
  align-content: center;
}
.studio__text {
  display: inline-flex;
  flex-direction: column;
  margin-left: 10px;
}
