.b-desc-show {
  width:40px;
  height:40px;
  background: #087cc8;
  position:absolute;
  top:10px;
  left:0;
  border-radius: 0 10px 10px 0;
  display: flex;
  justify-content: center;
  align-items:center;
  transition:all 0.5s ease;
  opacity: 1;
  img {
    width:70%;
    transition:all 0.5s ease;
  }
  cursor:pointer;
  z-index: 110;
  &.active {
    left:calc(100% - 40px);
    border-radius: 10px 0 0 10px;
    img {
      transform: rotate(180deg);
    }
  }
  @include for-desktop-up {
    display: none;
  }

}
