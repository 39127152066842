.b-video-desc {
  position: relative;
  perspective: 1000px;
  h4 {
    margin-bottom: 1em;
  }

}
.video-desc--item {
  opacity: 0;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  margin-bottom:0;
  flex: 1 0 auto;
  @include for-desktop-up {
    margin-bottom: 1.5em;
  }
  min-width: 300px;
  &.is-active {
    opacity: 1;
  }
}
.b-video-desc-holder {
  margin-bottom: 2.5em;

}