.c-facts-list {
  background-color: #f5f5f5;
  padding: 36px 0 30px 0;
  margin-bottom: 68px;
   .u-main-wr {
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
   }
  .b-fact-item {
    min-width:276px;
  }
}
