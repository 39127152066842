.b-flat-choose {
  width:80%;
  &.__single-flat {

    width:60%;
  }
  @include for-desktop-up {
    width:70%;

  }
}

