.b-cross
{
    position: relative;

    width: 30px;
    height: 30px;

    cursor: pointer;
    svg
    {
        width: 100%;
    }
}
