.b-title-social {
  display:flex;

  .b-social-ico_list {
       display:flex;
       align-items: center;
       flex:2;
       min-width: 180px;
  }
  .social-ico_item {
    margin-right: 10px;
  }
}
.title-social--text {
  min-width: 100%;
  flex:11;
}
