.b-legend {
  width:100%;
  display: flex;
  justify-content: center;
  padding-top: 0.5%;
}
.el-legend-item {
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  border-image-slice: 1;
  padding: 5px 16px 5px 5px;
  margin-right: 20px;

}
.el-legend-item_color {
  width: 28px;
  height: 28px;
}
.el-legend-item_text {
  font-size: 20px;
  margin-left: 5px;
  line-height: 1;
}
.c-grey-light {
  background: #ccc;
}
.c-legend-res {
  background: #c6e7ff;
}
.c-legend-sale {
  background:#087cc8;
}