.b-floor-choose {
  height:100%;
  position: relative;

}
.el-floor-choose_img {
  position:absolute;
  height:100%;
  top:0;
  right:50%;
  transform: translateX(55%);
  z-index: -1;
  img {
    height:100%;
  }
}
.el-floor-choose_svg {
  position: absolute;
  right:50%;
  transform: translateX(55%);
  height: 100%;
  .el-svg-inner {
    height: 100%;
  }
}
.j-deco-floor {
  cursor: pointer;
}
