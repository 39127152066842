.b-contact {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  align-content: center;
  height:100%;

  .icon {
    color:$accent-color;
  }
}
.contact--item {
  display: inherit;
  align-items: center;
  align-content: center;
  margin-right: 30px;
  min-width: 150px;
  margin-bottom: 1em;
  @include for-tablet-landscape-up {
    margin-bottom: 0;
  }
}
.contact--item__text {
  display:inline-flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 9px;
  a {
    color:currentColor;
  }
}
