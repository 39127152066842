.b-back {
   display:flex;
   align-items: center;
  color:currentColor;
  text-transform: uppercase;
  &:hover {
    .back-ico {
      color: #264d9a;
    }
  }
}
.back-ico {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  color:#15BBDD;
  transition: all 1s ease;
    svg {
    width:100%;
    height:100%;
  }
}
