$icons:location, mail, phone, skype;
$iconDefaultSize:2rem;
$iconSmallSize:1rem;
.icon {
  display: inline-block;
  width:$iconDefaultSize;
  height: $iconDefaultSize;
  background-repeat: no-repeat;
  background-size: cover;
  svg  {
    width:100%;
    max-height: 100%;
  }
}

.icon--small {
  width: $iconSmallSize;
  height: $iconSmallSize;
}

@each $icon in $icons {
  $index: index($icons, $icon) - 1;

  .icon--#{$icon} {
    background-position: 0 #{-$index * $iconDefaultSize};

    &.icon--small {
      background-position: 0 #{-$index * $iconSmallSize};
    }
  }
}
