.b-accent-text {
  background: url("/assets/img/secure.jpg")no-repeat;
  background-size:cover;
  text-align: center;
  color:#fff;
  padding:4em 0 2em 0;
  background-position: top left;

}
.accent-text--title {
  text-transform: uppercase;
}
.accent-text--text {
  font-size: 1.2rem;
}