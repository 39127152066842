.b-desc {

  background: #fff;
  max-width:389px;
  z-index:100;
  padding:5% 5% 1% 5%;
  position: absolute;
  top:0;
  left:0;
  height:100%;
  right:0;
  overflow: auto;
  transform: translateX(-100%);
  &.active {
    transform: translateX(0);
  }
  transition:all 0.5s ease;
  @include for-desktop-up {
    transform: translateX(0);
    width:30%;
    padding:5% 2% 1% 1%;
    right:auto;
  }

  &.__not-absolute {
    position: relative;
  }
    &._no-bg {

      background: none;
    }
    &._pos-abs {
        position: absolute;
      left:0;
      top: 0;
    }

  .el-contacts-logo {

          margin-top: 7%;
          margin-bottom: 15px;
          img {
              width: 50%;
          }
      }
      .t-h3 {
          color: #cf8447;
      }
      .el-contacts-phone {
          font-size: 32px;
          font-weight: bold;
      }
      ._call-btn {

      }
      span {

      }
      p {


          time {
              font-weight: bold;
          }
      }


  .b-desc-list {
    margin: 3em 0 2em 0;
  }
  .b-back {
    position:absolute;
    bottom:10px;

  }
  .b-desc-list-wide {

  }
  .b-def-btn {
    margin-bottom: 15px;
  }
}

.el-desc-tel {
    font-size: 32px;
    font-weight: bold;
}
