.b-check-with-text {
 display: flex;
  margin-right: 30px;
}

.check-input-field {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;

  input {
      width: 100%;
      height: 100%;

      &:focus {
          outline: none;
      }

      &:checked + label {

      }
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.check-text-field {
  user-select: none;

  label {
    user-select: none;
    cursor: pointer;
    line-height: 1.3;
    color: #000;
  }
}
