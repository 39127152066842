.b-quick-form {

  flex:1 0 auto;
  .input-text--inp {
    background: transparent;
    color:#fff;
    border-bottom: 1px solid #fff;
    width:100%;
    min-width: 300px;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #fff;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #fff;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
  }
  .input-text--label {
    color:#fff;
  }
}
.quick-form--body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom:2em ;
  .b-input-text{
    flex:1 0 auto;
    margin-right: 5%;
  }
}
.quick-form-title {
  margin-bottom: 1em;
  font-size: 2rem;
  @include for-phone-only {
    text-align: center;
  }
}