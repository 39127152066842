.b-row-form {
  display: flex;
  align-items: flex-end;
}
.row-form-img {
  margin-top: -30%;
  width:30%;
  max-width: 422px;
  display: none;
  @include for-tablet-portrait-up {
    display:block;
  }
}
