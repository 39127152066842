.b-article
{
  width: 100%;
  margin-bottom: 60px;

}
.b-article_header
{
  .b-article_header_image
  {
    width:100%;
    max-width: 800px;
    img
    {
      width: 100%;
    }
  }
  .b-article_header_desc
  {

  }
}

.b-article_main
{
  .b-article_main-header
  {

  }
  .b-article_main-subtitle
  {

  }

  .b-article_main-text
  {

  }
  .b-article_main-image
  {
    width: 100%;
    max-width: 500px;
    img
    {
      width: 100%;
    }

  }
}
.b-article_buttons
{
  width:100%;

  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 40px;
  color:#15BBDD;
}
