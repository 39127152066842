.b-btn
{
  height: 40px;
  padding: 0 4em;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  border: none;
  outline: none;
  &:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.28);
  }
  &:active {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.28);
  }
  &:focus {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.28);
  }
  &.__large {
    width:240px;
  }

}
.btn--grey {
  &:hover {

    background-color: #eeeeee;
  }
  &:active {

    background-color: #eeeeee;
  }
  &:focus {

    background-color: #eeeeee;
  }
}

.btn--blue {
  background-color: #117bc7;
  color:#fff;

  &:hover {

    background-color: #264d9a;
  }
  &:active {

    background-color: #264d9a;
  }
  &:focus {

    background-color: #264d9a;
  }
}
