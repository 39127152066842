.b-contacts-list {
  padding: 0;
  list-style: none;
  margin-top: 62px;
}
.contacts-list--holder {

  display: flex;
  flex-wrap: wrap;
  .b-ico-text {

    margin-right: 15px;
    margin-bottom: 16px;
    .icon {
      width:30px;
      height:30px;
    }
  }
}
