.svg-floor {
 opacity:0;
 cursor: pointer;
  &:hover {
    opacity:0.6;
  }
  &.active {
    opacity:0.6;
  }
}
.j-floor {
  fill: #087cc8;
}