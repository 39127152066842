$tabs-color:#c6e7ff;
$tabs-hover-color:#087cc8;
.b-tabs-holder {
  height: 32px;
  background-color: #ecf9fd;
  border-bottom: 2px solid $tabs-hover-color;
  display: flex;
  .row {
    flex-wrap: nowrap;
  }
  @include for-tablet-landscape-up {
    height: 50px;
  }
}

.tabs--item {
  height: 30px;
  max-width:calc(23% - 5px);
  position: relative;
  background: $tabs-color;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  color:inherit;
  font-size: 8px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 0.5em;
  text-align: center;
  @include for-tablet-portrait-up {
    font-size: 10px;
    margin-right: 1em;
  }
  @include for-tablet-landscape-up {
    height: 48px;
    font-size: 16px;
  }
  &::before{
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-width: 1.5em 0 1.5em 1em;
    border-style: solid;
    border-color: $tabs-color $tabs-color  $tabs-color transparent;
    left: -1em;
    display: none;
    @include for-tablet-landscape-up {
     display: inline-block;
    }
  }
  &:hover::before{
    border-color: $tabs-hover-color $tabs-hover-color $tabs-hover-color transparent;
  }
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent;
    border-left: 1em solid  $tabs-color;
    right: -1em;
    display: none;
    @include for-tablet-landscape-up {
      display: inline-block;
    }
  }
  &:hover::after{
    border-left-color: $tabs-hover-color;
  }
  &:hover {
    background: $tabs-hover-color;
    color:#fff;
  }
  &.is-active::before{
    border-color: $tabs-hover-color $tabs-hover-color $tabs-hover-color transparent;
  }

  &.is-active::after{
    border-left-color: $tabs-hover-color;
  }
  &.is-active {
    background: $tabs-hover-color;
    color:#fff;
  }
}