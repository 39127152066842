.b-sucsess-block {
  position: fixed;
  background: rgba(#087cc8, 0.5);
  left:0;
  right:0;
  top:0;
  bottom:0;
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  display: none;
  &.active {
    display: block;
  }
}
.sucsess-block-inner {
  background: #fff;
  padding: 2em;
}