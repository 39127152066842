.c-slider {
 position: relative;
  width:100%;
  height:100%;
  overflow: hidden;

}
.slider--item {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity: 0;
  z-index: 1;
  &:after {
    transition: opacity 1s ease;
  }
  &.current-slide {
    z-index: 99;
    opacity: 1;
    &:after {
      opacity: 1;
    }
  }
  &:after {
    content:'';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0;
    transition: opacity 1s ease;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0.4) 100%);
  }
}
.slider--item_img {
  width:100%;
  transform: scale(1);
  transition: all 0.5s ease;
}
.slider-arr {
  position: absolute;
  z-index: 99;
}

.slider-arrs--arr {
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  width: 42px;
  height: 42px;
  cursor: pointer;
  z-index: 99;
  svg {
    width: 42px;
    height: 42px;
  }
  &.arr-left {
     left:5%;
   }
  &.arr-right {
    right:5%;
  }
}
.j-slider--dots {
  position: absolute;
  z-index: 101;
  bottom:4%;
  display: flex;
  justify-content: center;
  width:100%;
  padding: 0;
  margin: 0;
  li {
    width:60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right:30px;
    box-shadow: -1px -2px 20px 1px rgba(0,0,0,0.8);
    padding: 0;
    position: relative;
    transition:all 0.5s ease;
    cursor: pointer;
    border:2px solid #fff;
    &.current-dot {
      transform: scale(1.5);
      border:2px solid $accent-color;
    }
    img {
      height:110%;
      position: absolute;
      top:0;
      border-radius: 50%;
    }
    &:hover {
      transform: scale(1.2);
    }
    &.is-active {
      transform: scale(1.2);
    }
  }
}
.slider--item_text {
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.61);
  color: #ffffff;
  font-family: "Gothic";
  font-size: 2.25rem;
  font-weight: 700;
  position: absolute;
  left:50%;
  top:calc(8% + 50px);
  width:60%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 110;
}
