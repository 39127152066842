.b-btn-right
{
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;
  color: black;
  .b-btn-right-image
  {
    position: relative;
    width: 24px;
    height: 24px;
    svg
    {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  .b-btn-right-text
  {
    font-size: .9rem;
    margin-right: 1rem;
  }
}