.b-desc-list-wide {
  margin: 46px 0;
  ._anim {
      animation: blinkOpacity  1s 3;
  }
}
.b-desc-list-wide_item {
  margin-bottom: 17px;
}
.b-desc-list-wide_item_num {
  color: $accent-color;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
}
.b-desc-list-wide_item_text {
  font-size: 16px;
  line-height: 1.9;
  border-bottom: 1px solid  $accent-color;
}
